<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="提现单号">
                <el-input size="small" v-model="form.withdraw_no"></el-input>
            </el-form-item>
            <el-form-item label="审核状态">
                <el-select v-model="form.status" placeholder="请选择" >
                  <el-option label="待审核" value="0"></el-option>
                  <el-option label="已审核" value="1"></el-option>
                  <el-option label="已打款" value="2"></el-option>
                   <el-option label="已驳回" value="-1"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="申请时间">
                <el-date-picker @change="getTime" value-format="timestamp" v-model="form.create_time" size="small" type="daterange" range-separator="~"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" @click="search" size="small">搜索</el-button>
                <el-button type="primary" @click="exportData" size="small">导出</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="create_time | getDateformat" label="申请时间" align="center"> 
                <template v-slot="{ row }">
                    <span>{{ getDateformat(row.create_time) }}</span>
                </template>   
            </el-table-column>
            <el-table-column prop="withdraw_no" label="提现单号" align="center"> </el-table-column>
            <el-table-column prop="property_name" label="物业名称" align="center"> </el-table-column>
            <el-table-column prop="order_total_price" label="订单金额" align="center"></el-table-column>
            <el-table-column prop="order_num" label="结算订单数" align="center"></el-table-column>
            <el-table-column prop="order_num" label="结算比例" align="center">
                <template v-slot="{ row }">
                    {{row.rate}}%
                </template>  
            </el-table-column>
            <el-table-column prop="property_settlement_money" label="物业结算金额" align="center"></el-table-column>
            <el-table-column prop="dk_money" label="打款金额" align="center"></el-table-column>
            <el-table-column label="审核状态" align="center">
                <template v-slot="{ row }">
                    <span>{{ row.status==0?'待审核':
                             row.status==1?"已审核":
                             row.status==2?"已打款":
                             row.status==-1?"已驳回":
                             row.status }}</span>
                </template>  
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="$router.push({ path: '/finance/withdrawDetail', query: { id: scope.row.id } })">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateServiceData"></Paging>
    </el-main>
</template>

<script>
import config from '@/util/config';
const industryName = '/Gateway'; //行业版本接口名称（用于更换重复接口）
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            showOptions: [
                {
                    value: 0,
                    label: '全部',
                },
                {
                    value: 1,
                    label: '否',
                },
                {
                    value: 2,
                    label: '是',
                },
            ],
            is_show: 0,
            name: '',
            list: [],
            dialogVisible: false,
            form: {
                page: 1,
                rows: 10,
                withdraw_no:'',
                status:'',
            }
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat,
        getList () {
            let data = this.form
            if (this.form.create_time && this.form.create_time.length) {
                data.start_time = this.form.create_time[0] / 1000;
                data.end_time = this.form.create_time[1] / 1000;
            }
            delete(data.create_time)
            this.$axios
                .post(this.$api.finance.withdrawList, data)
                .then(res => {
                    if (res.code == 0) {
                      let list = res.result.list;
                      this.total_number = res.result.total;
                      this.list = list;
                    } else {
                      this.$message.error(res.msg);
                    }
                });
        },
        exportData(){
            this.form.is_export = 1;
            let data = this.form
            if (this.form.create_time && this.form.create_time.length) {
                data.start_time = this.form.create_time[0] / 1000;
                data.end_time = this.form.create_time[1] / 1000;
            }
            delete(data.create_time)
            this.$axios
                .post(this.$api.finance.withdrawList, data)
                .then(res => {
                    if (res.code == 0) {
                        let path = res.result;
                        let a = document.createElement('a');
                        a.href = path;
                        a.id = 'download';
                        document.body.appendChild(a);
                        a.click();
                        let aDom = document.getElementById('download');
                        document.body.removeChild(aDom);
                    } else {
                      this.$message.error(res.msg);
                    }
                });
        },
        exportGoodsClass () {
            this.$axios.post(this.$api.goods.exportGoodsClass).then(res => {
                if (res.code == 0) {
                    let path = config.baseurl + '/' + res.result;
                    let a = document.createElement('a');
                    a.href = path;
                    a.id = 'download';
                    document.body.appendChild(a);
                    a.click();
                    let aDom = document.getElementById('download');
                    document.body.removeChild(aDom);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        editChange (row) {
            this.$axios
                .post(industryName + this.$api.goods.editType, {
                    id: row.id,
                    parent_id: row.parent_id,
                    name: row.name,
                    picture: row.picture,
                    sort: row.sort,
                    is_show: row.is_show,
                    type: row.type,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$store.dispatch('goods/getTypeList', {
                            industryName,
                            is_show: this.is_show,
                            name: this.name,
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        showValChange (val) {
            this.is_show = Number(val);
        },
        search () {
            this.getList()
        },
        //搜索分类
        searchType () {
            this.$store.dispatch('goods/getTypeList', {
                industryName,
                is_show: this.is_show,
                name: this.name,
            });
        },
        cancelSearch () {
            this.form = {}
            this.getList()
        },
        //编辑分类
        edit (row) {
            this.$router.push({
                path: '/gateway/goods/editGoodsType',
                query: {
                    info: JSON.stringify(row),
                },
            });
        },
        //删除分类
        remove (row) {
            this.$confirm('确认删除此商品分类？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(industryName + this.$api.goods.delType, {
                        id: row.id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.getList();
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                            });
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-table {
        flex: 1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}

.descriptions {
    font-size: 18px;
    font-weight: bold;
    padding: 30px 0;
    display: flex;

    .descriptions_item+.descriptions_item {
        margin-left: 50px;
    }
}
</style>
